import { M as MyUtil } from "./MyUtil.js";
import { n as normalizeComponent } from "./vueComponentNormalizer.js";
import "lodash";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("create-modal-composer", { attrs: { "title": "Add Budget Exchange Rate" } }, [_c("select-input", { key: "CountryId", attrs: { "label": "Country", "reference": "web-analytics.common.ppv-countries", "placeholder": "Select Country", "source": "countryId", "source-label": "countryCode", "value": _vm.country, "disabled": true, "rules": "required", "span": 12, "form-item": "" } }), _c("text-input", { key: "Currency", attrs: { "label": "Currency", "placeholder": "Type Currency", "rules": "required", "form-item": "", "span": 12 } }), _c("date-picker", { key: "FinYear", attrs: { "year-only": true, "label": "Fin Year", "placeholder": "Select Year", "format-date": "YYYY", "rules": "required", "span": 12, "form-item": "" } }), _c("text-input", { key: "ExchangeRate", attrs: { "label": "Exchange Rate", "placeholder": "Type Exchange Rate", "rules": _vm.regex1digits7frac, "span": 12, "form-item": "" } })], 1);
};
var staticRenderFns$1 = [];
var CreateBudgetExchangeRates_vue_vue_type_style_index_0_scoped_true_lang = /* @__PURE__ */ (() => "")();
const apiUrl$1 = "#{VUE_APP_API_URL}#";
const __vue2_script$1 = {
  name: "CreateBudgetExchangeRates",
  inject: ["crud"],
  mixins: [MyUtil],
  data() {
    return {
      apiUrl: apiUrl$1,
      country: this.crud.getQueryString("CountryID").value
    };
  },
  created() {
    this.storeValue("CountryId", this.country);
  },
  methods: {
    storeValue(resourceKey, value) {
      const createForm = this.createForm;
      this.createForm = {
        ...createForm,
        [resourceKey]: value
      };
      this.crud.updateEntity("createForm", {
        resourceKey,
        value
      });
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, "4241fa60", null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var CreateBudgetExchangeRates = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", [_c("resource", { attrs: { "name": "web-analytics.common.ppv-countries", "api-url": _vm.apiUrl } }), _c("resource", { attrs: { "name": "web-analytics.budget-exchange-rates", "api-url": _vm.apiUrl, "redirect-route": "/web-analytics/budget-exchange-rates" } }, [_c("create-budget-exchange-rates")], 1)], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: {
    CreateBudgetExchangeRates
  },
  data() {
    return {
      CreateBudgetExchangeRates,
      apiUrl
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
